import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const SubmissionRepository = RepositoryFactory.get('submission')
const state = {
  submission: {},
  submissions: [],
  firstSubmission: {},
  remainingTime: 0,
  logAnswers: {},
  showResult: false,
  doing: false,
  isClockRunning: false,
  currentPart: 0,
  loading: false
}

const actions = {
  async fetchSubmission({ commit, rootState, dispatch }, params = {}) {
    const res = await SubmissionRepository.fetch({
      ...params,
      userId: rootState.auth.auth.id
    })
    commit('setSubmission', res.data && res.data.length ? res.data[0] : {})
    if (res.data[0]) {
      dispatch('question/setQuestions', res.data[0].questions, { root: true })
      commit('setLogAnswers', res.data[0].answers)
    }
    commit('setfirstSubmission', res.data && res.data.length ? res.data[res.data.length - 1] : {})
    commit('setSubmissions', res.data)
    commit('setDoing', false)
    commit('setStateShowResult', false)
    commit('setClockStatus', false)
    return res.data
  },
  async fetchLastSubmission({ commit, rootState }, params = {}) {
    const res = await SubmissionRepository.fetchLast({
      ...params,
      userId: rootState.auth.auth.id
    })
    commit('setSubmissions', res.data)
    return res.data
  },
  async updateViolationsWhenTesting(_, id) {
    const res = await SubmissionRepository.updateViolations(id, {})
    return res.data
  },
  async submitTest({ commit, state, dispatch }, { id, ...submission }) {
    commit('setLoading', true)
    const res = await SubmissionRepository.submit(id, {
      ...submission,
    })
    commit('setSubmission', res.data)
    commit('setLogAnswers', res.data.answers)
    dispatch('question/setQuestions', res.data.questions, { root: true })
    dispatch('question/mapQuestionsCorrect', res.data.questions, { root: true })
    commit('setSubmissions', [...state.submissions, res.data])
    commit('setLoading', false)
    commit('setClockStatus', false)
    commit('setDoing', false)
    commit('setCurrentPart', 0)
    return res.data
  },
  async createSubmission({ commit, rootState, state, dispatch }, { type }) {
    commit('setLogAnswers', {})
    commit('setCurrentPart', 0)
    const submission = await SubmissionRepository.create({
      course: rootState.exam.exam.course,
      email: rootState.auth.auth.email,
      exam: rootState.exam.exam.id,
      name: rootState.auth.auth.name,
      state: "started",
      submitCount: state.submissions.length,
      type: type,
      userId: rootState.auth.auth.id,
    })
    commit('setSubmission', submission.data)
    dispatch('question/setQuestions', submission.data.questions, { root: true })
    commit('setDoing', true)
    commit('setStateShowResult', false)
    commit('setClockStatus', true)
    return submission.data
  },
  async saveLogAnswers({ commit, state }, answer) {
    const newData = {
      ...state.logAnswers,
      [`${answer.questionId}`]: answer
    }
    commit('setLogAnswers', newData)
    return await SubmissionRepository.update(state.submission.id, {
      state: 'doing',
      answers: newData,
      type: 'answer',
      log: answer
    })
  },
  async nextPart({ commit, rootState, state }, partIndex) {
    commit('setCurrentPart', partIndex)
    if (state.doing && !state.showResult) {
      if (rootState.exam.exam.settings.selectedTime === 'part-limited')
        commit('setTime', rootState.exam.exam.partition[partIndex].time)
      await SubmissionRepository.update(state.submission.id, {
        state: 'doing',
        answers: state.logAnswers,
        type: 'part',
        log: { index: partIndex }
      })
    }

  },
  setRemainingTime({ commit }, time) {
    commit('setTime', time)
  },
  setShowResult({ commit }, value) {
    commit('setStateShowResult', value)
    commit('setDoing', true)
  },
  backToExam({ commit }) {
    commit('setStateShowResult', false)
    commit('setDoing', false)
    commit('setCurrentPart', 0)
  },
  continueTest({ commit }) {
    commit('setDoing', true)
    commit('setClockStatus', true)
  },
  async fetchSubmissions({ commit, rootState }, params = {}) {
    // for report
    const res = await SubmissionRepository.fetch({
      ...params,
      userId: rootState.auth.auth.id
    })
    commit('setSubmissions', res.data)
    return res.data
  },
}

const mutations = {
  setSubmission(state, submission) {
    state.submission = submission
  },
  setfirstSubmission(state, submission) {
    state.firstSubmission = submission
  },
  reset(state) {
    state.submission = {}
  },
  setDoing(state, isDoing) {
    state.doing = isDoing
  },
  setSubmissions(state, submissions) {
    state.submissions = submissions
  },
  setLogAnswers(state, logAnswers) {
    state.logAnswers = logAnswers
  },
  setTime(state, time) {
    state.remainingTime = time
  },
  setStateShowResult(state, showResult) {
    state.showResult = showResult
  },
  setClockStatus(state, status) {
    state.isClockRunning = status
  },
  setCurrentPart(state, part) {
    state.currentPart = part
  },
  setLoading(state, loading) {
    state.loading = loading
  }
}

const getters = {
  getSubmission: state => {
    return state.submission
  },
  getFirstSubmission: state => {
    return state.firstSubmission
  },
  getSubmissions: state => {
    return state.submissions
  },
  getDoing: state => {
    return state.doing
  },
  getLogAnswers: state => {
    return state.logAnswers
  },
  getRemainingTime: state => {
    return state.remainingTime
  },
  getShowResult: state => {
    return state.showResult
  },
  isClockRunning: state => {
    return state.isClockRunning
  },
  getCurrentPart: state => {
    return state.currentPart
  },
  getLoading: state => {
    return state.loading
  }
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
}
