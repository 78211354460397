import learning from 'iztech-design-system-neo/dist/system/i18n/en/learning'

const EXERCISE = {
  ...learning.EXERCISE,
  SUBMIT_ACTION: {
    ...learning.EXERCISE.SUBMIT_ACTION,
    BTN_VIEW_CORRECTION: 'View notes & corrections',
    DENIED_LABEL: 'Denied',
    BTN_VIEW_DENY_REASON: 'View denied reason',
    BTN_RE_SUBMIT_VIA_QR: 'Re-submit via QR Code',
    BTN_SUBMIT_VIA_QR: 'Submit via QR Code',
  },
  DESCRIPTION: {
    TITLE: 'Description of homework',
    EMPTY_HINT: 'This homework has no description',
  },
  QR_POPUP: {
    TITLE: 'Please use your phone camera to scan below QR Code',
    QR_EXPIRATION_LABEL: 'Expired in:',
    BTN_RE_CREATE_QR: 'Re-create QR code',
  },
  UPLOAD_POPUP: {
    DATA_SUBMIT_WITH_LINK: 'Homework submitted by link',
    MSG_REQUIRED_LINK: 'Please recheck your link',
    MSG_EXCEED_SUBMIT_TIMES: 'You can only submit {0} time(s)',
    MSG_INVALID_SUBMIT_MOMENT: 'Invalid submit time',
    MSG_EXPIRED_QR_CODE: 'Your QR Code is expired, please re-create new one!',
    MSG_SUBMIT_SUCCESS: 'Submit homework successfully',
    MSG_EXCEED_FILE_LIMIT: 'You can only upload {0} files and total size of these files must not exceed {1}',
  },
  RESULT: {
    ...learning.EXERCISE.RESULT,
    MSG_EMPTY_CORRECTION: 'Cannot open now because of data error',
  },
  QR_UPLOAD: {
    TITLE: 'Submit your homework here',
    DESCRIPTION: 'You have only {0} minutes to submit yours',
    MSG_INVALID_PERMISSION: 'You have no access!',
  },
}

const EMPTY_UNIT_MESSAGE = {
  TITLE: 'Setting-up lesson',
  BODY: 'Your viewed lesson is being set-up. Please move to other lesson.'
}

const NON_EXIST_UNIT_MESSAGE = {
  TITLE: 'Non-existed lesson',
  BODY: 'Your viewed lesson is not existed. Please refresh the website or move to other lesson.'
}

const VIDEO = {
  ...learning.VIDEO,
  MSG_IS_CONVERTING: 'Video is being converted, please try again later!',
  // legacy player - play through s3
  MSG_S3_ERROR_TITLE: 'Cannot load video',
  MSG_S3_ERROR_DESCRIPTION: 'Please contact technical support staffs.',
  MSG_S3_INVALID_LINK: 'Not found video link!',
}

const EXAMINATION = {
  ...learning.EXAMINATION,
  RESULT: {
    ...learning.EXAMINATION.RESULT,
    QUESTION_FILL_BLANK_ANSWER_NOT_FOUND: 'no answer',
  },
  POPUP_AFTER_SUBMIT: {
    TITLE: 'Congratulation',
    DESCRIPTION: 'You have completed the exam',
    DISPLAYED_MARK_LABEL: 'With score is'
  },
  EXPIRE_TIME_CALCULATED_BY_QUESTION: "Calculated by each question",
  GROUP_QUESTION_LABEL: "Group question",
  MSG_LOST_FOCUS: "You've lost focus {0} times !",
  BTN_NEXT_PARTITION: "Next partition",
  BTN_PREV_PARTITION: "Prev partition",
  MSG_CONFIRM_NEXT_PARTITION: 'After changing to next partition, you will no longer go back and edit your answer in current partition. Are you sure you want to do this?',
  SUBMIT_ACTION: {
    ...learning.EXAMINATION.SUBMIT_ACTION,
    ONGOING_LABEL_PARTITION: "partition",
  },
  MSG_OBO_CONFIRM_SUBMIT: 'Are you sure you want to submit?',
}

const COURSE_LESSON = {
  ...learning.COURSE_LESSON,
  BTN_REFRESH: "Refresh site"
}

const CERTIFICATE = {
  ...learning.CERTIFICATE,
  MSG_NO_CERT_FOR_COURSE: 'Current course does not have certificate!',
  MSG_CREATE_LOADING: 'Creating your certificate...',
  MSG_CREATE_FAIL: 'Creating certificate fail, please try again later!',
  RELATED_COURSES_TITLE: 'May you interest',
}

const COMMON_RULES = {
  ...learning.COMMON_RULES,
  RESULT_PUBLISHED_TIME_LABEL: 'Result will be pusblished at:',
}

export default {
  ...learning,
  EMPTY_UNIT_MESSAGE,
  NON_EXIST_UNIT_MESSAGE,
  EXERCISE,
  VIDEO,
  EXAMINATION,
  COURSE_LESSON,
  CERTIFICATE,
  COMMON_RULES,
  COMPLETED: 'completed',
  DESCRIPTION: 'Description',
  BTN_DOWNLOAD: 'Download',
  BTN_NEXT_LESSON: 'Next lesson',
  SUBMIT_CONFIRMATION_ANSWER: 'Are you sure to submit?',
  MSG_SUBMIT_WARNING: 'You must submit before go to other lesson!',
  TIME: 'Time',
  VIEW_RESULT: 'View result',
  EXACT_MINUTES_AND_SECONDS: '{0} minutes {1} seconds',
  NOT_IN_ALLOWANCE_TIME_AND_TRY_AGAIN: 'Not in access time, please come back later!',
  EXIT_COURSE_WARNING: 'Do you really want to exit this course?',
  POPUP_CONFIRM_CONTINUE: 'Question changing Confirmation',
  POPUP_CONFIRM_SUBMIT: 'Submit Confirmation',
  MSG_NO_ACCESS_COURSE: 'You have no access to this course',
  MSG_NO_NEXT_LESSON: 'There is no next lesson',
	MSG_CANNOT_MOVE_TO_NEXT_LESSON: 'You have learned to the last lesson. There is not any lesson else.',
}
