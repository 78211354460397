var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isSupportZoom
        ? _c("ZoomDialog", {
            attrs: { loading: _vm.loading, state: _vm.dialog, zooms: _vm.data }
          })
        : _vm._e(),
      _vm.isSupportZoom
        ? _c(
            "div",
            {
              staticClass:
                "d-flex fixed cursor-pointer bg-white elevation-1 pa-2 rounded-pill",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.fetchZooms()
                }
              }
            },
            [
              _c("img", {
                staticStyle: { width: "100px" },
                attrs: { src: "/learn-static/zoom.png" }
              }),
              _c("span", { staticClass: "red--circle" })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }