var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "400", persistent: _vm.authPopup.isPersistent },
      model: {
        value: _vm.authPopup.isShow,
        callback: function($$v) {
          _vm.$set(_vm.authPopup, "isShow", $$v)
        },
        expression: "authPopup.isShow"
      }
    },
    [
      _c(
        "div",
        { staticStyle: { "background-color": "#ffffff" } },
        [
          _c("Authencation", {
            attrs: {
              state: _vm.authPopup.state,
              isLoading: _vm.authPopup.isLoading
            },
            on: {
              "change-state": function(state) {
                return _vm.$authPopup.show(state)
              },
              onRequestSignUp: function(data) {
                return _vm.onRequestSignUp(data)
              },
              onRequestSignIn: function(data) {
                return _vm.onRequestSignIn(data)
              },
              onRequestForgotPassword: function(data) {
                return _vm.onRequestForgotPassword(data)
              },
              onConfirmForgotPassword: function(data) {
                return _vm.onConfirmForgotPassword(data)
              },
              onRequestChangePassword: function(data) {
                return _vm.onRequestChangePassword(data)
              }
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }