import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_RESOURCE + /resource/
const resourceStatisticURL = process.env.VUE_APP_API_RESOURCE + '/resource-statistic'

const getHLSdataVideo = params => {
  return Repository(
    'https://akprowk3pj.execute-api.ap-southeast-1.amazonaws.com/prod/resource/' + `signed-hls/${params.resourceId}`
  ).fetch()
}

const fetch = params => {
  return Repository(baseURL).fetch(params)
}

const fetchOne = id => {
  return Repository(baseURL).fetchOne(id)
}

const create = params => {
  return Repository(baseURL).create(params)
}

const update = (id, params) => {
  return Repository(baseURL).update(id, params)
}

const saveLoadTimes = (params) => {
  return Repository(resourceStatisticURL).createWithoutAccessToken(params)
}

const remove = id => {
  return Repository(baseURL).delete(id)
}

export default {
  fetch,
  fetchOne,
  create,
  update,
  remove,
  getHLSdataVideo,
  saveLoadTimes
}
