var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "element view-more" }, [
    _c(
      "div",
      { staticClass: "view-more__trigger--top" },
      [
        _vm._t("view-more__trigger--top", null, {
          on: _vm.toggleFullState,
          state: _vm.isFull
        })
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass: "view-more__wrapper",
        style: { height: _vm.localHeightWrapper + "px" }
      },
      [
        _c(
          "div",
          { ref: "view-more__content", staticClass: "view-more__content" },
          [_vm._t("default")],
          2
        )
      ]
    ),
    _c(
      "div",
      { staticClass: "view-more__trigger--bottom" },
      [
        _vm._t("view-more__trigger--bottom", null, {
          on: _vm.toggleFullState,
          state: _vm.isFull
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }