const baseURL = process.env.VUE_APP_API_PERMISSION + '/user-book'
import vendor from '@/store/modules/vendor'
import axios from 'axios'
import auth from '@/store/modules/auth'
import Repository from '@/api/core'

const fetch = params => {
  return Repository(baseURL).fetch(params)
}

const fetchOne = id => {
  return Repository(baseURL).fetchOne(id)
}

const interact = (id, params, option) => {
  return axios.put(
    baseURL + `/interact/${id}?vendorId=${vendor.state.vendor.vendorId}`,
    params,
    {
      headers: {
        Authorization: auth.state.auth.accessToken
      }
    },
    option
  )
}

const enroll = params => {
  return Repository(baseURL + '/enroll').create({
    ...params,
    user: {name: auth.state.auth.name, email: auth.state.auth.email},
    userId: auth.state.auth.id
  })
}

export default {
  fetch,
  fetchOne,
  interact,
  enroll
}
