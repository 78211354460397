import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const CommentRepository = RepositoryFactory.get('comment')
import helpers from '@/helpers'
const { countTypeEnums } = helpers.commentEnums
import get from 'lodash-es/get'

const state = {
  comments: [],
  total: 0,
  replies: [],
  commentCount: 0,
  replyCount: 0,
  ignoredComments: [],
  ignoredReplies: [],
}

const actions = {
  async createComment({ commit, state, rootState }, params) {
    if (!rootState.auth.authenticated || !params.objectId) return
    const { addToList, ...other } = params
    const res = await CommentRepository.create({
      ...other,
      userId: rootState.auth.auth.id,
      userEmail: rootState.auth.auth.email,
      userName: rootState.auth.auth.name,
      userAvatarLink: rootState.auth.auth.avatar,
    })
    if (res && res.data) {
      if (addToList) {
        if (params.type === 'comment') {
          commit('addComment', { ...res.data, userDisplayName: res.data.userName } || {})
        } else {
          // commit('addReply', res.data || {})
          commit('updateTotalReplies', res.data || {})
        }
        commit('setTotal', res.data ? state.total + 1 : state.total)
      }
      return res.data
    }
    throw new Error('Submit comment failed!')
  },
  async updateComment({ commit, rootState }, { id, addToList, ...other }) {
    if (!id) return
    const res = await CommentRepository.update(id, { ...other, userId: rootState.auth.auth.id })
    if (res && res.data) {
      if (addToList) {
        if (other.type === 'comment') {
          commit('updateComment', res.data || {})
        } else {
          commit('updateReply', res.data || {})
        }
      }
      return res.data
    }
    throw new Error('Submit reply failed!')
  },
  async fetchComments({ commit }, params) {
    const res = await CommentRepository.fetch({ ...params, status: 'publish' })
    commit('setComments', res.data || [])
  },
  async fetchCount({ commit }, { countType, ...params }) {
    const res = await CommentRepository.count({ ...params, status: 'publish' })
    switch (countType) {
      case countTypeEnums.TOTAL:
        commit('setTotal', res.data || 0)
        break
      case countTypeEnums.COMMENT:
        commit('setCommentCount', res.data || 0)
        break
      case countTypeEnums.REPLY:
        commit('setReplyCount', res.data || 0)
        break
      default:
        break
    }
  },
  async fetchRepliesOfComment({ commit }, params) {
    const res = await CommentRepository.fetch({ ...params, status: 'publish', _sort: 'createdAt:1' })
    commit('setReplies', res.data || [])
    return res.data
  },
  increaseReactionInComment({ commit, state, rootState }, itemId) {
    const { itemIndex, stateProp } = getDataToUpdateReactionInfo(itemId, state)
    if (itemIndex < 0) return
    commit('increaseReactionInComment', {
      itemIndex,
      userId: rootState.auth.auth.id,
      userDisplayName: rootState.auth.auth.name,
      stateProp,
    })
  },
  decreaseReactionInComment({ commit, state, rootState }, itemId) {
    const { itemIndex, stateProp } = getDataToUpdateReactionInfo(itemId, state)
    if (itemIndex < 0) return
    commit('decreaseReactionInComment', {
      itemIndex,
      userId: rootState.auth.auth.id,
      stateProp,
    })
  },

}

const mutations = {
  setComments(state, comments = []) {
    if (comments.length) {
      const formatComments = comments.map(c => {
        return {
          ...c,
          userDisplayName: c.userName,
          createdAt: new Date(c.createdAt).getTime(),
          avatar: c.userAvatarLink || helpers.DEFAULT_IMAGE.avatar,
          likeCount: get(c, 'data.totalReaction_LOVE', 0)
        }
      })
      if (state.ignoredComments.length > 0) {
        state.comments = state.comments.concat(formatComments.filter(c => state.ignoredComments.indexOf(c.id) < 1))
      } else {
        state.comments = state.comments.concat(formatComments)
      }
    }
  },
  addComment(state, comment) {
    console.log('add comment');
    state.comments = [comment, ...state.comments]
    state.ignoredComments.concat(comment.id)
  },
  updateComment(state, comment) {
    if (comment && comment.id) {
      const index = state.comments.findIndex(item => item.id === comment.id)
      if (index >= 0) {
        const oldComment = { ...state.comments[index] }
        state.comments.splice(index, 1, { ...oldComment, ...comment })
      }
    }
  },
  updateReply(state, reply) {
    if (reply && reply.id) {
      const index = state.replies.findIndex(item => item.id === reply.id)
      if (index >= 0) {
        const oldReply = { ...state.replies[index] }
        state.replies.splice(index, 1, { ...oldReply, ...reply })
      }
    }
  },
  resetComments(state, objectType = 'ONLINE_COURSE') {
    state.comments = state.comments.filter(comment => comment.objectType !== objectType)
    state.ignoredComments = state.ignoredComments.filter(comment => comment.objectType !== objectType)
  },
  setTotal(state, total) {
    state.total = total
  },
  setCommentCount(state, count) {
    state.commentCount = count
  },
  setReplyCount(state, count) {
    state.replyCount = count
  },
  // TODO: using updateComment instead of updateTotalReplies
  updateTotalReplies(state, reply) {
    if (reply && !!reply.commentId) {
      const commentIndex = state.comments.findIndex(comment => comment.id === reply.commentId)
      if (commentIndex > -1) {
        state.comments.splice(commentIndex, 1, { ...state.comments[commentIndex], totalReplies: (state.comments[commentIndex].totalReplies || 0) + 1 })
      }
    }
  },
  setReplies(state, replies = []) {
    if (replies.length) {
      const formatReplies = replies.map(r => {
        return {
          ...r,
          userDisplayName: r.userName,
          createdAt: new Date(r.createdAt).getTime(),
          avatar: r.userAvatarLink || helpers.DEFAULT_IMAGE.avatar,
          likeCount: get(r, 'data.totalReaction_LOVE', 0)
        }
      })
      if (state.ignoredReplies.length > 0) {
        state.replies = state.replies.concat(formatReplies.filter(r => state.ignoredReplies.indexOf(r.id) < 1))
      } else {
        state.replies = formatReplies
      }
    }
  },
  addReply(state, reply) {
    state.replies = [reply, ...state.replies]
    state.ignoredReplies.concat(reply.id)
  },
  resetReplies(state, objectType = 'ONLINE_COURSE') {
    state.replies = state.replies.filter(reply => reply.objectType !== objectType)
    state.ignoredReplies = state.ignoredReplies.filter(reply => reply.objectType !== objectType)
  },
  increaseReactionInComment(state, { itemIndex, userId, userDisplayName, stateProp }) {
    const oldItem = { ...state[stateProp][itemIndex] }
    const info = {
      data: {
        totalReaction_LOVE: (oldItem.totalReaction_LOVE || 0) + 1
      },
      likeBy: (oldItem.likeBy || []).concat({ userId, userDisplayName }),
      likeCount: (oldItem.likeCount || 0) + 1
    }
    state[stateProp].splice(itemIndex, 1, { ...oldItem, ...info })
  },
  decreaseReactionInComment(state, { itemIndex, userId, stateProp }) {
    const oldItem = { ...state[stateProp][itemIndex] }
    const { data, likeBy, likeCount } = oldItem
    const info = {}
    if (data && data.totalReaction_LOVE > 0) info.data = { totalReaction_LOVE: oldItem.totalReaction_LOVE - 1 }
    if (Array.isArray(likeBy) && likeBy.length) {
      info.likeBy = likeBy.filter(lb => lb.userId !== userId)
    }
    if (likeCount > 0) info.likeCount = likeCount - 1
    state[stateProp].splice(itemIndex, 1, { ...oldItem, ...info })
  }
}

const getters = {
  comments(state) {
    return state.comments || []
  },
  total(state) {
    return state.total || 0
  },
  replies(state) {
    return state.replies
  },
  commentCount(state) {
    return state.commentCount
  },
  replyCount(state) {
    return state.replyCount
  },
}

function getDataToUpdateReactionInfo(itemId, state) {
  let itemIndex = -1
  let stateProp = ''
  const cIndex = state.comments.findIndex(c => c.id === itemId)
  const rIndex = state.replies.findIndex(r => r.id === itemId)
  if (cIndex > -1) {
    itemIndex = cIndex
    stateProp = 'comments'
  } else if (rIndex > -1) {
    itemIndex = rIndex
    stateProp = 'replies'
  }
  return { itemIndex, stateProp }
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}