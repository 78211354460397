<template>
	<v-dialog v-model="authPopup.isShow" width="400" :persistent="authPopup.isPersistent">
		<div style="background-color: #ffffff">
			<Authencation
				:state="authPopup.state"
				:isLoading="authPopup.isLoading"
				@change-state="state => $authPopup.show(state)"
				@onRequestSignUp="data => onRequestSignUp(data)"
				@onRequestSignIn="data => onRequestSignIn(data)"
				@onRequestForgotPassword="data => onRequestForgotPassword(data)"
				@onConfirmForgotPassword="data => onConfirmForgotPassword(data)"
				@onRequestChangePassword="data => onRequestChangePassword(data)"
			/>
		</div>
	</v-dialog>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default {
	data() {
		return {
			authPopup: this.$authPopup.authPopupData
		}
	},
	computed: {
		...mapGetters({
			isDisableRegistration: 'vendor/getIsDiableRegistration'
		})
	},
	methods: {
		...mapActions({
			signIn: 'auth/signIn',
			signUp: 'auth/signUp',
			changePassword: 'auth/changePassword',
			sendCodeForgotPassword: 'auth/sendCodeForgotPassword',
			submitForgotPassword: 'auth/submitForgotPassword'
		}),
		close() {
			this.$authPopup.close()
		},
		checkValidate() {
			if (this.isDisableRegistration) {
				this.$popup.error(this.$vuetify.lang.t('$vuetify.AUTHENTICATION.MSG_SIGNUP_DISABLE'))
				return false
			} else return true
		},
		handlerDate(date) {
			if (!date) return ''
			let format = date.length === 8 ? 'DD/MM/YY' : 'DD/MM/YYYY'
			return this.$utils.dayjs(date, format).toISOString()
		},
		async onRequestSignUp(data) {
			const validate = this.checkValidate()
			if (!validate) return
			try {
				this.$authPopup.setLoading(true)
				await this.signUp({
					...data,
					birthdate: this.handlerDate(data.birthdate)
				})
				this.$message.success(this.$vuetify.lang.t('$vuetify.AUTHENTICATION.MSG_SIGNUP_SUCCESS'))
				this.$authPopup.close()
			} catch (error) {
				let errorMessage = this.$vuetify.lang.t('$vuetify.AUTHENTICATION.MSG_SIGNUP_FAIL')
				if (error.code === '1051') {
					errorMessage = this.$vuetify.lang.t('$vuetify.AUTHENTICATION.MSG_SIGNUP_EXIST_EMAIL')
					this.$authPopup.setLoading(false)
					return this.$message.error(errorMessage)
				}
				this.$authPopup.setLoading(false)
				this.$message.error(errorMessage)
				this.$authPopup.close()
			}
		},
		async onRequestSignIn(data) {
			try {
				this.$authPopup.setLoading(true)
				await this.signIn(data)
				this.$message.success(this.$vuetify.lang.t('$vuetify.AUTHENTICATION.MSG_SIGNIN_SUCCESS'))
				this.$authPopup.close()
			} catch (error) {
				let errorMessage = this.$vuetify.lang.t('$vuetify.AUTHENTICATION.MSG_WRONG_CREDENTIALS')
				if (error.code === '2011')
					errorMessage = this.$vuetify.lang.t('$vuetify.AUTHENTICATION.MSG_SIGNIN_NOT_VERIFIED')
				else if (error.code === '2009')
					errorMessage = this.$vuetify.lang.t('$vuetify.AUTHENTICATION.MSG_SIGNIN_NOT_EXIST')
				this.$message.error(errorMessage)
				this.$authPopup.setLoading(false)
			}
		},
		async onRequestForgotPassword(data) {
			try {
				this.$authPopup.setLoading(true)
				await this.sendCodeForgotPassword(data)
				await this.$message.success(
					this.$vuetify.lang.t('$vuetify.AUTHENTICATION.MSG_REQUEST_FORGOT_PASSWORD_SUCCESS')
				)
				this.$authPopup.setLoading(false)
				this.$authPopup.show('confirmForgotPassword')
			} catch (error) {
				this.$message.error(
					this.$vuetify.lang.t('$vuetify.AUTHENTICATION.MSG_REQUEST_FORGOT_PASSWORD_FAIL')
				)
				this.$authPopup.close()
			}
		},
		async onConfirmForgotPassword(data) {
			try {
				this.$authPopup.setLoading(true)
				await this.submitForgotPassword(data)
				this.$message.success(
					this.$vuetify.lang.t(
						'$vuetify.AUTHENTICATION.MSG_CONFIRM_FORGOT_PASSWORD_SUCCESS'
					)
				)
				this.$authPopup.close()
			} catch (error) {
				this.$message.error(
					this.$vuetify.lang.t('$vuetify.AUTHENTICATION.MSG_CONFIRM_FORGOT_PASSWORD_FAIL')
				)
				this.$authPopup.close()
			}
		},
		async onRequestChangePassword(data) {
			try {
				this.$authPopup.setLoading(true)
				await this.changePassword(data)
				this.$message.success(
					this.$vuetify.lang.t('$vuetify.AUTHENTICATION.MSG_REQUEST_CHANGE_PASSWORD_SUCCESS')
				)
				this.$authPopup.close()
			} catch (error) {
				this.$message.error(
					this.$vuetify.lang.t('$vuetify.AUTHENTICATION.MSG_REQUEST_CHANGE_PASSWORD_FAIL')
				)
				this.$authPopup.close()
			}
		}
	}
}
</script>
