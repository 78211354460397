import Repository from '@/api/core'
import queryString from 'query-string'
import vendor from '@/store/modules/vendor'
const BASE_URL = process.env.VUE_APP_API_LEARNING_PATH + '/learning-path'

const fetch = (params) => {
  const query = queryString.stringify({...params, vendorId: vendor.state.vendor.vendorId})
  return Repository(BASE_URL + `?${query}`).fetchWithoutAccessToken()
}

const fetchOne = (id) => {
  return Repository(BASE_URL).fetchOneWithoutAccessToken(`${id}?_populate=courses`)
}

export default {
  fetch,
  fetchOne,
}