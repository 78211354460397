var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "base__loading",
      class: [
        _vm.fluid ? "base__loading--fluid" : "",
        _vm.size ? "base__loading--" + _vm.size : "",
        _vm.block ? "base__loading--block" : ""
      ]
    },
    [
      _c("DecoratorProgressCircular", {
        attrs: { indeterminate: "", color: _vm.color, size: _vm.actualSize }
      }),
      _vm._t("base__loading__text")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }