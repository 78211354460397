import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const UserCourseRepository = RepositoryFactory.get('userCourse')

const state = {
  userCourse: null,
}

const actions = {
  async enrollUserCourse({ commit }, data) {
    const userCourse = await UserCourseRepository.enroll(data)
    commit('setUserCourse', userCourse.data)
    return userCourse.data
  },
  async count(_, params) {
    const result = await UserCourseRepository.count(params)
    return result.data
  },
  async fetchUserCourse({ rootState, commit }, { courseId }) {
    if (rootState.auth.authenticated) {
      let data = await UserCourseRepository.fetch({
        userId: rootState.auth.auth.id,
        course: courseId
      })
      commit('setUserCourse', data.data && data.data.length ? data.data[0] : {})
      return data.data
    }
  },
  async interactUserCourse({ commit }, { id, userCourse }) {
    const res = await UserCourseRepository.interact(id, userCourse)
    commit('setUserCourse', res.data)
    return res.data
  },
  async interactionQrCode(_, { unitId, userId }) {
    const res = await UserCourseRepository.interactionQrCode({ unitId: unitId, userId: userId })
    return res.data
  },
  createNewView(_, { views, newView }) {
    views.push({ ...newView, count: 1 })
    return views
  },
  updateView(_, { views, updateView, isContinue }) {
    return views.map(view => {
      if (view.unitId === updateView.unitId) {
        let viewCount = view.isDone ? (view.count ? view.count + 1 : 1) : 1
        if (view.type === 'video' || view.type === 'vimeo') {
          if (isContinue) viewCount = view.count
        }
        return {
          ...updateView,
          count: viewCount
        }
      } else return view
    })
  },
  async createOrUpdateViews({ dispatch }, { newView, viewsOfStudent, isContinue }) {
    let viewData = {}
    if (!viewsOfStudent) {
      viewData = { views: [newView] }
    } else {
      let existedView = viewsOfStudent.find(view => view.unitId === newView.unitId)
      if (existedView) {
        viewData = {
          views: await dispatch('updateView', {
            views: viewsOfStudent,
            updateView: newView,
            isContinue,
          })
        }
      } else {
        viewData = {
          views: await dispatch('createNewView', {
            views: viewsOfStudent,
            newView
          })
        }
      }
    }
    return viewData
  },
  calculateCourseProgress(_, { viewsOfStudent, syllabus }) {
    let alllessonIds = []
    syllabus.forEach(syllabus => {
      const lessonIds = (syllabus.lessons || []).map(v => v.id)
      // if syllabus contains child syllabus, get lessons in child syllabus to compare
      if (Array.isArray(syllabus.child) && syllabus.child.length) {
        syllabus.child.forEach(childSyllabus => {
          const childLessonIds = (childSyllabus.lessons || []).map(v => v.id)
          alllessonIds = alllessonIds.concat(childLessonIds)
        })
      }
      alllessonIds = alllessonIds.concat(lessonIds)
    })
    if (!viewsOfStudent) return 0
    const doneViews = viewsOfStudent.filter(view => {
      if (view.type === 'video' || view.type === 'vimeo') {
        if (view.count > 1) return true
      }
      return view.isDone === true && alllessonIds.find(id => id === view.unitId)
    })
    return doneViews && doneViews.length ? Math.floor((doneViews.length / alllessonIds.length) * 100) : 0
  }
}

const mutations = {
  setUserCourse(state, userCourse) {
    state.userCourse = userCourse
  }
}

const getters = {
  getUserCourse: state => {
    return state.userCourse
  },
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
