<template>
  <v-app id="app">
    <router-view />
    <Seo />
    <Zoom />
    <BaseMessage />
    <BasePopup />
    <BaseAuthenticationPopup />
  </v-app>
</template>
<script>
  import Seo from '@/pages/Seo'
  import {mapActions, mapMutations, mapGetters} from 'vuex'
  import firebase from 'firebase/app'
  import helpers from '@/helpers/index.js'
  import 'firebase/messaging'
  import '@/registerServiceWorker'
  import Zoom from '@/pages/Zoom'

  export default {
    components: {
      Seo,
      Zoom
    },
    methods: {
      ...mapActions({
        fetchVendor: 'vendor/fetchVendor',
        getSettingsVendor: 'vendor/getSettingsVendor',
        initAuthen: 'auth/initAuthen',
        fetchCart: 'cart/fetchCart',
        fetchNotifications: 'notification/fetchNotifications',
        countNotification: 'notification/countNotification'
      }),
      ...mapMutations({
        setBaitNotifications: 'notification/setBaitNotifications'
      }),
      async getNotifications() {
        if (this.authenticated) {
          await this.fetchNotifications({_limit: 10, userId: this.authUser.id})
          await this.countNotification({userId: this.authUser.id})
        }
      },
      async initFirseBase() {
        this.getNotifications()
        firebase.initializeApp(helpers.FIREBASE_CONFIG)
        const messaging = firebase.messaging()
        messaging.onMessage(async payload => {
          setTimeout(() => {
            this.$message.success(payload.notification.title, 6000)
          }, 2000)
          console.log('trigger fetch noti')
          this.getNotifications()
        })
        messaging
          .getToken({
            vapidKey: helpers.FIREBASE_WEB_PUSH_CERT
          })
          .then(currentToken => {
            if (currentToken) this.$notify.setToken(currentToken)
            else console.log('No registration token available. Request permission to generate one.')
          })
          .catch(() => {
            console.log('*** An error occurred while retrieving token firebase. ')
          })
      },
      setBaitNotif() {
        if (this.baitNotificationSettings.isNotificationBaited)
          this.setBaitNotifications(this.baitNotificationSettings.baitNotifications)
      }
    },
    async created() {
      try {
        await this.fetchVendor()
      } catch (error) {
        return this.$router.push('/not-found')
      }
      await this.initAuthen()
      await this.getSettingsVendor()
      this.fetchCart()
      this.initFirseBase()
      this.setBaitNotif()
    },
    computed: {
      ...mapGetters({
        themeData: 'vendor/getThemedata',
        authenticated: 'auth/getAuthenticated',
        baitNotificationSettings: 'vendor/getBaitNotification',
        currentLanguage: 'vendor/getCurrentLanguage',
        isDisplayMarketHeader: 'vendor/getHeaderSetting',
        authUser: 'auth/getAuthUser'
      })
    },
    watch: {
      authenticated: {
        handler(val) {
          if (val) {
            this.getNotifications()
          }
        },
        immediate: true
      },
      currentLanguage: {
        handler(val) {
          if (val) {
            this.$vuetify.lang.current = val
          }
        },
        immediate: true
      }
    }
  }
</script>
<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
</style>
