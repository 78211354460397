import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_TEACHER + '/teacher'

const fetch = params => {
  return Repository(baseURL).fetch(params)
}

const fetchOne = id => {
  return Repository(baseURL).fetchOneWithoutAccessToken(id)
}

const create = params => {
  return Repository(baseURL).create(params)
}

const update = (id, params) => {
  return Repository(baseURL).update(id, params)
}

export default {
  fetch,
  fetchOne,
  create,
  update
}
