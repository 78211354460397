import queryString from 'query-string'
import vendor from '@/store/modules/vendor'
import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_PAYMENT
const utilityURL = process.env.VUE_APP_API_UTILITY

const checkCombo = params => {
  const query = queryString.stringify({
    ...params,
    vendorId: vendor.state.vendor.vendorId
  })
  return Repository(baseURL + '/combo/my-combo' + `?${query}`).fetch()
}

const checkCoupon = params => {
  return Repository(baseURL + '/coupon/validate').fetch(params)
}

const createOrder = params => {
  return Repository(baseURL + '/bill').create(params)
}

const updateOrder = (id, params) => {
  return Repository(baseURL + '/bill').update(id, params)
}

const validateOrder = params => {
  const query = queryString.stringify({
    userId: params.userId,
    vendorId: vendor.state.vendor.vendorId
  })
  return Repository(baseURL + `/bill/validate?${query}`).create(params.items)
}

const createContact = params => {
  return Repository(utilityURL + '/order').createWithoutAccessToken(params)
}

const updateContact = (id, params) => {
  return Repository(utilityURL + '/order').update(id, params)
}

const createSubmitForm = params => {
  return Repository(utilityURL + '/contact').createWithoutAccessToken(params)
}

const validateAffiliate = params => {
  return Repository(baseURL + '/affiliate/validate').fetch(params)
}

export default {
  checkCombo,
  createOrder,
  updateOrder,
  createContact,
  updateContact,
  checkCoupon,
  createSubmitForm,
  validateOrder,
  validateAffiliate,
}
