import { RepositoryFactory } from '@/api/factory/repositoryFactory'
import questionUtils from '../../helpers/specificUtils/questionUtils'
const QuestionRepository = RepositoryFactory.get('question')


const state = {
  questions: [],
  count: 0,
  partQuestions: [],
  totalTime: 0,
}

const actions = {
  async fetchQuestions({ commit }, params = {}) {
    const res = await QuestionRepository.fetch({
      ...params
    })
    commit('setQuestions', res.data || [])
    return res.data
  },
  resetQuestions({ state }) {
    state.questions = []
    state.count = 0
    state.partQuestions = []
    state.totalTime = 0
  },
  setQuestions({ state, dispatch, rootState }, questions) {
    const isMatrixTest = rootState.exam.exam.type === 'random-question-test'
    const isPartition = rootState.exam.exam.settings.isPartition
    const isRandom = rootState.exam.exam.settings.isRandomQuestion
    if (isMatrixTest && isPartition) {
      let newQuestions = questions
      let parts = rootState.exam.exam.partition
      let generateQuestion = rootState.submission.submission.generateQuestion
      if (generateQuestion && generateQuestion.questions) {
        newQuestions = newQuestions.map(i => {
          const questionInfo = generateQuestion.questions.find(q => q.question === i.id)
          return { ...i, ...questionInfo }
        })
      }
      newQuestions.forEach(q => {
        if (parts[q.part]) {
          if (parts[q.part].questions) {
            parts[q.part].questions.push(q.id)
          } else parts[q.part].questions = [q.id]
        }
      })
      dispatch('exam/setExam', { ...rootState.exam.exam, partition: parts }, { root: true })
    }
    state.questions = isMatrixTest ? questions.map((q, index) => { return { ...q, index } }) : questions
    if (isRandom) state.questions = questionUtils.setIndexRandomQuestions(questions)
    state.partQuestions = isPartition ? isMatrixTest ? questions.map((q, index) => { return { ...q, index } }) : questions : []
    if (isPartition && isRandom) state.partQuestions = questionUtils.setIndexRandomQuestions(questions)
  },
  mapQuestionsCorrect({ state, rootState }, questions) {
    const isPartition = rootState.exam.exam.settings.isPartition
    let rootQuestions = isPartition ? state.partQuestions : state.questions
    const questionsWithCorrect = rootQuestions.map(q => {
      const resultQuestion = questions.find(item => item.id === q.id)
      return resultQuestion.correct ? { ...q, correct: resultQuestion.correct } : q
    })
    state.questions = questionsWithCorrect
    state.partQuestions = questionsWithCorrect
  },
  setPartQuestion({ state }, questions) {
    state.questions = questions
  }
}

const mutations = {
  setQuestions(state, questions) {
    state.questions = questions
    return questions
  },
  reset(state) {
    state.questions = []
  },
  setTotalTime(state, totalTime) {
    state.totalTime = totalTime
  },
}

const getters = {
  getQuestions: (state) => {
    return state.questions.length ? state.questions.sort((a, b) => a.index - b.index) : state.questions
  },
  getpartQuestions: (state) => {
    return state.partQuestions
  },
  getTotalTime: (state) => {
    return state.totalTime
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
