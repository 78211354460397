var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _c("Seo"),
      _c("Zoom"),
      _c("BaseMessage"),
      _c("BasePopup"),
      _c("BaseAuthenticationPopup")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }