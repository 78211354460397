import get from 'lodash-es/get'
import utils from '@/helpers/utils.js'
import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const OnlineCourseRepository = RepositoryFactory.get('onlineCourse')
const state = {
  courses: [],
  course: {},
  layout: [],
  count: 18,
  setSearchItems: null

}
const actions = {
  async fetchCourses({ commit, rootState }, params = {}) {
    const res = await OnlineCourseRepository.fetch({ ...params, visibleHidden: false });
    const permissions = rootState.userPermission.userPermissions
    let courses = (res.data || [])
      .map(utils.mutateDataWithRating)
      .map(data => ({
        ...data,
        learnerCount: (data.learner || 0) + utils.getInitialLearnerCount(data)
      }))
    courses = courses.map(course => {
      const existedPermission = permissions.find(item => item._id === course._id)
      if (!existedPermission) return course
      const limitTime = utils.getLimitTime(existedPermission.limitTime)
      return existedPermission.limitTime ? { ...course, limitTime: limitTime } : course
    })
    commit("setCourses", courses);
    return courses;
  },
  async searchGlobal({ commit }, params = {}) {
    const res = await OnlineCourseRepository.search(params);
    commit('setSearchItems', res.data);
    return res.data;
  },
  async fetchLayout({ commit }, courseId) {
    const res = await OnlineCourseRepository.fetchLayout(courseId);
    commit('setLayout', res.data);
    return res.data;
  },
  async fetchCourseById({ commit }, id) {
    const res = await OnlineCourseRepository.fetchOne(id);
    commit("setCourse", res.data || {});
    return res.data;
  },
  async fetchCourseIndependent(_, id) {
    const res = await OnlineCourseRepository.fetchOne(id)
    return res.data || {}
  },
  async fetchCoursesIndependent(_, params = {}) {
    const res = await OnlineCourseRepository.fetch(params)
    return res.data || []
  }
}
const mutations = {
  setCourses(state, courses) {
    state.courses = courses
  },
  setLayout(state, layout) {
    state.layout = layout
  },
  setSearchItems(state, searchItems) {
    state.setSearchItems = searchItems
  },
  setCourse(state, course) {
    state.course = course
  }
}
const getters = {
  getCount: state => {
    return state.count
  },
  getLayout: state => {
    return state.layout
  },
  getCourses: state => {
    return state.courses
  },
  getCourse: state => {
    return state.course
  },
  getIsFacebookCommentShown: state => {
    return get(state.course, 'data.isFacebookCommentEmbed', false)
  },
  getFacebookCommentAppId: state => {
    return get(state.course, 'data.facebookCommentAppId', '')
  },
  getIsRatingFake: state => {
    return get(state.course, 'data.isRatingFake', false)
  },
}
export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
