/* eslint-disable */
import vuetify from '@/plugins/vuetify'
import get from 'lodash-es/get'
import specificUtils from '@/helpers/specificUtils/index.js'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import dayjsVi from 'dayjs/locale/vi'
import dayjsEn from 'dayjs/locale/en'
import dayjsJp from 'dayjs/locale/ja'

const getLocale = (currentLang = 'vi') => {
	switch (currentLang) {
		case 'vi': return dayjsVi
		case 'en': return dayjsEn
		case 'jp': return dayjsJp
		default: return dayjsVi
	}
}

dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)

const onDownloadSyllabus = syllabus => {
	const file = syllabus
	if (!file) return
	const { origin = {} } = file
	let { link = '' } = origin
	if (!link) return
	if (!link.includes('http')) link = combineLinkWithBucket(link)
	window.open(convertURL(link), '_blank')
}

const clearUnicode = (alias = '', hyphen = true) => {

	var str = alias
	str = str.toLowerCase()
	str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
	str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
	str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
	str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
	str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
	str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
	str = str.replace(/đ/g, 'd')
	str = str.replace(
		/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
		' '
	)
	str = str.replace(/ + /g, ' ')
	if (hyphen)
		str = str
			.trim()
			.split(' ')
			.join('-')
	return str.trim()
}

const combineLinkWithBucket = linkResource => {
	if (linkResource.includes('http')) return linkResource
	return process.env.VUE_APP_AWS_BUCKET_S3 + '/' + linkResource
}

const wrapperLinkImage = (image = {}) => {
	let originLink = convertURL(combineLinkWithBucket(image.origin.link))
	return {
		...image,
		link: image.link
			? convertURL(combineLinkWithBucket(image.link))
			: originLink,
		origin: {
			...image.origin,
			link: originLink
		}
	}
}

const bytesToSize = (bytes) => {
	var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes == 0) return '0 Byte';
	var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

const truncate = (input) => {
	if (input.length > 5) {
		return input.substring(0, 16) + '...';
	}
	return input;
};

const convertURL = (url, withoutCloudfront) => {
	if (!url) return url
	let convertedUrl = url;
	if (!url.includes('https') && url.includes('http')) {
		let urlWithHttps = url.replace('http', 'https');
		convertedUrl = urlWithHttps;
	}
	if (convertedUrl.includes(process.env.VUE_APP_AWS_BUCKET_S3) && !withoutCloudfront) {
		convertedUrl = convertedUrl.replace(process.env.VUE_APP_AWS_BUCKET_S3, process.env.VUE_APP_AWS_BUCKET_CLOUD_FRONT)
	}
	if (convertedUrl.includes(process.env.VUE_APP_AWS_OLD_BUCKET_S3) && !withoutCloudfront) {
		convertedUrl = convertedUrl.replace(process.env.VUE_APP_AWS_OLD_BUCKET_S3, process.env.VUE_APP_AWS_BUCKET_CLOUD_FRONT)
	}
	if (convertedUrl.includes(process.env.VUE_APP_AWS_OLD_BUCKET_S3_2) && !withoutCloudfront) {
		convertedUrl = convertedUrl.replace(process.env.VUE_APP_AWS_OLD_BUCKET_S3_2, process.env.VUE_APP_AWS_BUCKET_CLOUD_FRONT)
	}
	return convertedUrl
}

const getLinkWithoutCache = link => {
	const convertedLink = convertURL(link)
	return `${convertedLink}?time=${Date.now()}`
}

const parseScore = (score, number) => {
	return Number(score.toFixed(number))
}

const exceedTimesWatchVideo = (authuser, currentLesson, conditionWatchVideo,) => {
	if (conditionWatchVideo && conditionWatchVideo.isCountExceedWatchVideo) {
		const authUserId = authuser.id
		let data = JSON.parse(
			window.localStorage.getItem('exceed-times-watch-video')
		)
		let payload = data || {}
		let lesson = get(payload, `${authUserId}.${currentLesson.id}`, null)
		if (lesson) payload[authUserId][currentLesson.id]++
		else payload[authUserId] = { ...payload[authUserId], [currentLesson.id]: 1 }
		localStorage.setItem('exceed-times-watch-video', JSON.stringify(payload))
	}
}

const getInitialLearnerCount = (object) => {
	const isFakeLearner = get(object, "data.isFakedLearner", false)
	if (isFakeLearner) {
		const initialStudents = get(object, "data.totalLearnedStudents", 0)
		const incrementStudents = get(object, "data.incrementStudents", 0)
		if (incrementStudents > 0) {
			const dateDiff = new Date() - new Date(get(object, "data.incrementStartDate"))
			return initialStudents + incrementStudents * (parseInt(dateDiff / (1000 * 3600 * 24)) + 1)
		}
		return initialStudents
	}
	return 0
}

const delay = ms => new Promise(res => setTimeout(res, ms))

const canShowDiscussion = (currentCourse = {}, currentUnit = {}) => {
	const isDiscussionAllowedInCourse = get(currentCourse, 'data.canEnableDiscussion', false)
	if (isDiscussionAllowedInCourse) {
		const isDiscussionAllowedInUnit = get(currentUnit, 'data.canEnableDiscussion')
		if (typeof isDiscussionAllowedInUnit === 'boolean') return isDiscussionAllowedInUnit
		return true
	}
	return false
}

const parseFileSize = (size = 0) => {
	if (isNaN(size) || Number.isNaN(size)) return ''
	const fileSizeTypes = ['B', 'KB', 'MB', 'GB', 'TB']
	let result = size, count = 0
	while (result >= 1024) {
		result /= 1024
		++count
	}
	return `${result.toFixed(2)} ${fileSizeTypes[count]}`
}

const convertDateToDateTimeVietnamese = (date, hasSecondInTime = true) => {
	const displayedDate = dayjs(date).format('DD/MM/YYYY')
	const displayedTime = dayjs(date).format(hasSecondInTime ? 'HH:mm:ss' : 'HH:mm')
	return `${displayedDate}, lúc ${displayedTime}`
}

const convertRangeTime = (time) => {
	var diff = Date.parse(new Date(time)) - Date.now();

	var seconds = Math.floor(diff / 1000),
		minutes = Math.floor(seconds / 60),
		hours = Math.floor(minutes / 60),
		days = Math.floor(hours / 24),
		months = Math.floor(days / 30),
		years = Math.floor(days / 365);

	seconds %= 60;
	minutes %= 60;
	hours %= 24;
	days %= 30;
	months %= 12;
	if (years < 0 && months < 0 && days < 0) return false
	years = years ? years + ' năm ' : ''
	months = months ? months + ' tháng ' : ' '
	days = days ? days + ' ngày ' : '0 ngày'
	return years + months + days
}
const checkGreaterMinScore = (score, settings) => {
	if (settings.hasMinScore && settings.minScore) {
		return score >= Number(settings.minScore)
	}
}

const getLimitTime = (limitTime) => {
	if (!limitTime) return null
	const isTimeOut = new Date().getTime() > new Date(limitTime).getTime()
	const parseTime = isTimeOut ? 'timeout' : limitTime ? convertRangeTime(limitTime) : null
	return parseTime
}

export default {
	combineLinkWithBucket,
	convertURL,
	onDownloadSyllabus,
	getLinkWithoutCache,
	wrapperLinkImage,
	exceedTimesWatchVideo,
	parseScore,
	getInitialLearnerCount,
	delay,
	canShowDiscussion,
	parseFileSize,
	dayjs,
	truncate,
	bytesToSize,
	clearUnicode,
	convertDateToDateTimeVietnamese,
	getLocale,
	checkGreaterMinScore,
	convertRangeTime,
	getLimitTime,
	...specificUtils
}