import learning from 'iztech-design-system-neo/dist/system/i18n/jp/learning'

const EXERCISE = {
  ...learning.EXERCISE,
  SUBMIT_ACTION: {
    ...learning.EXERCISE.SUBMIT_ACTION,
    BTN_VIEW_CORRECTION: 'コメントと訂正閲覧',
    DENIED_LABEL: '拒否済み',
    BTN_VIEW_DENY_REASON: '理由表示',
    BTN_RE_SUBMIT_VIA_QR: 'QRコードでテスト提出',
    BTN_SUBMIT_VIA_QR: 'QRコードでテスト提出',
  },
  DESCRIPTION: {
    TITLE: '問題内容',
    EMPTY_HINT: 'この問題は説明がありません',
  },
  QR_POPUP: {
    TITLE: 'カメラを使用して以下のQRコードをスキャンしてください',
    QR_EXPIRATION_LABEL: '有効期限：',
    BTN_RE_CREATE_QR: 'QRコード再作成',
  },
  UPLOAD_POPUP: {
    DATA_SUBMIT_WITH_LINK: 'このテストはパスで提出します。',
    MSG_REQUIRED_LINK: 'パスを再度ご確認ください。',
    MSG_EXCEED_SUBMIT_TIMES: '{0}回のみ提出できます。',
    MSG_INVALID_SUBMIT_MOMENT: '時間が無効です。',
    MSG_EXPIRED_QR_CODE: 'QRコードの有効期限が切れています。別のQRコードを生成してください。',
    MSG_SUBMIT_SUCCESS: '宿題が正常に提出されました',
    MSG_EXCEED_FILE_LIMIT: 'アップロードファイルは{0}件だけで、30MBを超えないようにしてください',
  },
  RESULT: {
    ...learning.EXERCISE.RESULT,
    MSG_EMPTY_CORRECTION: '今、データエラーのため開けません。',
  },
  QR_UPLOAD: {
    TITLE: '提出はこちら',
    DESCRIPTION: 'QRコードの生成から提出までの時間は{0}分です。',
    MSG_INVALID_PERMISSION: 'アクセスできません。',
  },
}

const EMPTY_UNIT_MESSAGE = {
  TITLE: 'レッスン設定中',
  BODY: 'ご覧いただいているレッスンが今、設定されています。別のレッスンにご移動ください。'
}

const NON_EXIST_UNIT_MESSAGE = {
  TITLE: 'レッスン不在',
  BODY: 'ご覧いただいているレッスンが存在していません。ページをリロードするか、別のレッスンに切り替えてください。'
}

const VIDEO = {
  ...learning.VIDEO,
  MSG_IS_CONVERTING: '動画が処理しています。後で、お試しください。',
  // legacy player - play through s3
  MSG_S3_ERROR_TITLE: '動画を読み込めません。',
  MSG_S3_ERROR_DESCRIPTION: '技術サポートに連絡してください。',
  MSG_S3_INVALID_LINK: '動画のパスが見つかりません。',
}

const EXAMINATION = {
  ...learning.EXAMINATION,
  RESULT: {
    ...learning.EXAMINATION.RESULT,
    QUESTION_FILL_BLANK_ANSWER_NOT_FOUND: '未回答',
  },
  POPUP_AFTER_SUBMIT: {
    TITLE: 'おめでとうございます',
    DESCRIPTION: 'テストは完了しました。',
    DISPLAYED_MARK_LABEL: 'あなたの点数は'
  },
  EXPIRE_TIME_CALCULATED_BY_QUESTION: "各問ごとに",
  GROUP_QUESTION_LABEL: "問題グループ",
  MSG_LOST_FOCUS: "フォーカスを{0}回失いました。",
  BTN_NEXT_PARTITION: "後のセクション",
  BTN_PREV_PARTITION: "前のセクション",
  MSG_CONFIRM_NEXT_PARTITION: '次のセクションに進むと、このセクションの回答に戻って編集することはできなくなります。よろしいでしょうか？',
  SUBMIT_ACTION: {
    ...learning.EXAMINATION.SUBMIT_ACTION,
    ONGOING_LABEL_PARTITION: "セクション",
  },
  MSG_OBO_CONFIRM_SUBMIT: 'エッセイを提出してもよろしいですか?',
}

const COURSE_LESSON = {
  ...learning.COURSE_LESSON,
  BTN_REFRESH: "リフレッシュ"
}

const CERTIFICATE = {
  ...learning.CERTIFICATE,
  MSG_NO_CERT_FOR_COURSE: 'コースは証明書を提供しません。',
  MSG_CREATE_LOADING: '証明書を作成しています。',
  MSG_CREATE_FAIL: '証明書の作成に失敗しました。',
  RELATED_COURSES_TITLE: 'あなたは興味があるかもしれません。',
}

const COMMON_RULES = {
  ...learning.COMMON_RULES,
  RESULT_PUBLISHED_TIME_LABEL: '結果を得る時間',
}

export default {
  ...learning,
  EMPTY_UNIT_MESSAGE,
  NON_EXIST_UNIT_MESSAGE,
  EXERCISE,
  VIDEO,
  EXAMINATION,
  COURSE_LESSON,
  CERTIFICATE,
  COMMON_RULES,
  COMPLETED: '完了',
  DESCRIPTION: '説明',
  BTN_DOWNLOAD: 'ダウンロード',
  BTN_NEXT_LESSON: '次のレッスン',
  SUBMIT_CONFIRMATION_ANSWER: '提出してもよろしいでしょうか？',
  MSG_SUBMIT_WARNING: '別のテストに移る前に、テストを提出する必要があります。!',
  TIME: '時間',
  VIEW_RESULT: '結果を見る',
  EXACT_MINUTES_AND_SECONDS: '{0}分{1}秒',
  NOT_IN_ALLOWANCE_TIME_AND_TRY_AGAIN: '許可期間外にアクセスしています。後でお試しください。',
  EXIT_COURSE_WARNING: 'このコースを終了してもよろしいですか?',
  POPUP_CONFIRM_CONTINUE: '次の問題移動確認',
  POPUP_CONFIRM_SUBMIT: '提出確認',
  MSG_NO_ACCESS_COURSE: 'このページにアクセスできません',
  MSG_NO_NEXT_LESSON: '次の講義はありません',
	MSG_CANNOT_MOVE_TO_NEXT_LESSON: 'これはあなたの最後のレッスンになります。これ以上の講義はありません。',
}
