var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: "" },
      model: {
        value: _vm.popupData.show,
        callback: function($$v) {
          _vm.$set(_vm.popupData, "show", $$v)
        },
        expression: "popupData.show"
      }
    },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-center" },
          [
            _vm.popupData.type === "success"
              ? _c("v-icon", { attrs: { size: "100", color: "green" } }, [
                  _vm._v("mdi-checkbox-marked-circle-outline")
                ])
              : _c("v-icon", { attrs: { size: "100", color: "red" } }, [
                  _vm._v("mdi-alert-circle-outline")
                ])
          ],
          1
        ),
        _c("div", { staticClass: "popup__title" }, [_vm._v(_vm._s(_vm.title))]),
        _c("div", { staticClass: "popup__message mb-6" }, [
          _vm._v(_vm._s(_vm.message))
        ]),
        _c(
          "div",
          {
            staticClass: "d-flex justify-center pt-0",
            class: { "justify-space-between": _vm.popupData.type === "proceed" }
          },
          [
            _vm.popupData.type === "proceed"
              ? _c(
                  "DecoratorButton",
                  {
                    staticClass: "ma-0 elevation-0",
                    attrs: {
                      isLoading: _vm.loading,
                      color: "red",
                      className: "text-uppercase"
                    },
                    on: { click: _vm.done }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$vuetify.lang.t("$vuetify.BUTTON.CONFIRM")) +
                        " "
                    )
                  ]
                )
              : _vm._e(),
            _vm.message ===
              _vm.$vuetify.lang.t(
                "$vuetify.AUTHENTICATION.MSG_ACCOUNT_SIGNED_IN_OTHER_DEVICE"
              ) || _vm.popupData.type === "success"
              ? _c(
                  "DecoratorButton",
                  {
                    attrs: { solid: "", variation: "primary" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.closePopup.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$vuetify.lang.t("$vuetify.BUTTON.CLOSE")) +
                        " "
                    )
                  ]
                )
              : _c(
                  "DecoratorButton",
                  {
                    attrs: { solid: "", variation: "primary" },
                    on: {
                      click: function($event) {
                        _vm.popupData.show = false
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$vuetify.lang.t("$vuetify.BUTTON.CLOSE")) +
                        " "
                    )
                  ]
                )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }