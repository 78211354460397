import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const leaderboardRepository = RepositoryFactory.get('leaderboard')
const state = {
  rankOfStudent: null,
  count: 0
}
const actions = {
  async fetchRankStudent({commit, rootState}, params) {
    const res = await leaderboardRepository.fetchRankStudent(rootState.auth.auth.id, params)
    commit('setRankStudent', {...res.data['_doc'], rank: res.data.rank} || [])
    return res.data
  },
  async count({commit}, params) {
    const res = await leaderboardRepository.count(params)
    commit('setCount', res.data || 0)
    return res.data
  },
  async createRankForStudent(_, params) {
    const res = await leaderboardRepository.create(params)
    return res.data
  },
  async refresh({commit}) {
    commit('setRankStudent', null)
  }
}
const mutations = {
  setRankStudent(state, rank) {
    state.rankOfStudent = rank
  },
  setCount(state, count) {
    state.count = count
  }
}
const getters = {
  getRankOfStudent: state => {
    return state.rankOfStudent
  },
  getCount: state => {
    return state.count
  }
}
export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
