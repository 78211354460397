import max from 'lodash-es/max'
import min from 'lodash-es/min'
const mapQuestionsGroup = (questions) => {
  const listTypeGroup = ['group', 'single-choice-child', 'multiple-choice-child', 'fill-blank-child']
  let rawQuestions = questions.filter(qe => listTypeGroup.indexOf(qe.type) < 0)
  let listGroups = questions.filter(q => q.type === 'group')
  listGroups = listGroups.map(group => {
    group.children = group.questions.map(item => {
      let questionId = typeof item === 'string' ? item : item.id
      const question = questions.find(qe => qe.id === questionId)
      return {
        ...question,
        parentId: group.id
      }
    }).filter(item => item.id)
    return group
  })
  rawQuestions = rawQuestions.concat(listGroups)
  return rawQuestions.sort((a, b) => a.index - b.index)
}

const setIndexRandomQuestions = (allQuestions) => {
  let questions = allQuestions.filter(q => !q.type.includes('child'))
  let randomQuestions = []
  let currentIndex = 0
  questions.forEach(q => {
    if (q.type === 'group') {
      randomQuestions.push({ ...q, index: currentIndex })
      q.questions.forEach(child => {
        const childQuestion = allQuestions.find(i => i.id === child.toString())
        randomQuestions.push({ ...childQuestion, index: currentIndex })
        currentIndex++
      })
    } else {
      randomQuestions.push({ ...q, index: currentIndex })
      currentIndex++
    }
  })
  return randomQuestions
}

const transformQuestionPart = (questionsInPart) => {
  let currentIndex = 0
  let newQuestions = questionsInPart.map(q => {
    if (q.type === 'group') {
      let newQuestion = { ...q, index: currentIndex }
      let child = q.children.map(child => {
        let newChild = { ...child, index: currentIndex }
        currentIndex++
        return newChild
      })
      newQuestion = { ...newQuestion, children: child }
      return newQuestion
    } else {
      let newQuestion = { ...q, index: currentIndex }
      currentIndex++
      return newQuestion
    }
  })
  return newQuestions
}

const getFromToQuestionGroup = (question) => {
  if (question.children && question.children.length > 1) {
    const listIndex = question.children.map(child => Number(child.index))
    return `${min(listIndex) + 1}-${max(listIndex) + 1}`
  }
  return 'đơn'
}

export default {
  setIndexRandomQuestions,
  mapQuestionsGroup,
  getFromToQuestionGroup,
  transformQuestionPart
}
