var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CommentList",
    {
      attrs: {
        replies: _vm.displayedReplies,
        defaultAvatar: _vm.defaultAvatar,
        comments: _vm.displayedComments,
        currentUserId: _vm.userProfile ? _vm.userProfile.id : "",
        currentUserAvatar: _vm.userProfile
          ? _vm.userProfile.avatar
          : _vm.defaultAvatar,
        maxDisplayedCharLength: _vm.contentTruncatedLimit
      },
      on: {
        create: _vm.onCreateComment,
        reply: _vm.onCreateReply,
        fetchReplies: _vm.onFetchReplies,
        update: _vm.onUpdate,
        like: _vm.onLikeComment,
        unlike: _vm.onUnlikeComment
      }
    },
    [
      _c(
        "template",
        { slot: "comment-load-more-button" },
        [
          _vm.canShowLoadmore
            ? _c(
                "DecoratorButton",
                { attrs: { text: "" }, on: { click: _vm.fetchMoreComments } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$vuetify.lang.t("$vuetify.BUTTON.VIEW_MORE")) +
                      " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }