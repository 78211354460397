import axios from 'axios'
import Repository from '@/api/core'
import auth from '@/store/modules/auth'
import vendor from '@/store/modules/vendor'
const baseURL = process.env.VUE_APP_API_PERMISSION + '/user-course'

const fetch = params => {
  return Repository(baseURL).fetch(params)
}

const count = params => {
  return Repository(`${baseURL}/count`).fetchWithoutAccessToken(params)
}

const fetchOne = id => {
  return Repository(baseURL).fetchOne(id)
}

const interact = (id, params, option) => {
  return axios.put(
    baseURL + `/interact/${id}?vendorId=${vendor.state.vendor.vendorId}`,
    params,
    {
      headers: {
        Authorization: auth.state.auth.accessToken
      }
    },
    option
  )
}
const interactionQrCode = params => {
  return Repository(baseURL + '/interaction').create({
    ...params,
  })
}

const enroll = params => {
  return Repository(baseURL + '/enroll').create({
    ...params,
    user: { name: auth.state.auth.name, email: auth.state.auth.email },
    userId: auth.state.auth.id
  })
}

export default {
  fetch,
  fetchOne,
  interact,
  enroll,
  count,
  interactionQrCode
}
