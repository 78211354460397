<template>
  <v-dialog v-model="popupData.show" width="500" persistent>
    <div class="popup__content">
      <div class="d-flex justify-center">
        <v-icon v-if="popupData.type === 'success'" size="100" color="green">mdi-checkbox-marked-circle-outline</v-icon>
        <v-icon v-else size="100" color="red">mdi-alert-circle-outline</v-icon>
      </div>
      <div class="popup__title">{{ title }}</div>
      <div class="popup__message mb-6">{{ message }}</div>
      <div class="d-flex justify-center pt-0" :class="{'justify-space-between': popupData.type === 'proceed'}">
        <DecoratorButton
          v-if="popupData.type === 'proceed'"
          :isLoading="loading"
          color="red"
          @click="done"
          class="ma-0 elevation-0"
          className="text-uppercase"
        >
          {{ $vuetify.lang.t('$vuetify.BUTTON.CONFIRM') }}
        </DecoratorButton>
        <DecoratorButton
          v-if="
            message === $vuetify.lang.t('$vuetify.AUTHENTICATION.MSG_ACCOUNT_SIGNED_IN_OTHER_DEVICE') ||
              popupData.type === 'success'
          "
          @click.native="closePopup"
          solid
          variation="primary"
        >
          {{ $vuetify.lang.t('$vuetify.BUTTON.CLOSE') }}
        </DecoratorButton>

        <DecoratorButton v-else @click="popupData.show = false" solid variation="primary">
          {{ $vuetify.lang.t('$vuetify.BUTTON.CLOSE') }}
        </DecoratorButton>
      </div>
    </div>
  </v-dialog>
</template>
<script>
  export default {
    data() {
      return {
        popupData: this.$popup.data,
        loading: false
      }
    },
    computed: {
      title() {
        const popupTitle = this.popupData.title || ''
        if (popupTitle.indexOf('$vuetify') > -1) return this.$vuetify.lang.t(popupTitle)
        return popupTitle
      },
      message() {
        const popupMessage = this.popupData.message || ''
        if (typeof popupMessage === 'object') {
          if (typeof popupMessage.message === 'string' && popupMessage.message.indexOf('$vuetify') > -1) {
            return this.$vuetify.lang.t(popupMessage.message)
          }
          return popupMessage
        }
        if (popupMessage.indexOf('$vuetify') > -1) return this.$vuetify.lang.t(popupMessage)
        return popupMessage
      }
    },
    methods: {
      closePopup() {
        this.$popup.close()
        this.$router.push('/')
      },
      async done() {
        this.loading = true
        setTimeout(() => {
          this.popupData.done()
          this.loading = false
          this.popupData.show = false
        }, 1000)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .popup__content {
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(9, 30, 66, 0.25);
    padding: 24px;
  }

  .popup__title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #3e3e3c;
  }

  .popup__message {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #79797c;
  }
</style>
