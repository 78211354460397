import UserPermission from '@/api/repository/userPermissionRepository'

const state = {
  userPermissions: [],
  userPermissionsBook: [],
  permissionAccessCourse: {}
}

const actions = {
  async fetchUserPermissions({ commit }, userId) {
    const res = await UserPermission.fetch({ userId })
    const currentDate = new Date().getTime()
    const filterCourse = res.data.courses.filter(course => {
      if (!course.limitTime || course.limitTime && new Date(course.limitTime) > currentDate) return course
    })
    commit('setUserPermissionsCourse', filterCourse)
    commit('setUserPermissionsBook', res.data.books)
    commit('setUserCoursePermissions', res.data.userCourses)
    return res.data
  },
  async checkPermissionAccessCourse({ commit }, courseId) {
    try {
      const res = await UserPermission.fetchOne({
        relationId: courseId
      })
      commit('setPermissionAccessCourse', res.data)
      return res.data
    } catch {
      commit('setPermissionAccessCourse', {})
      return {}
    }
  },
  async checkPermissionAccessBook(_, bookId) {
    const res = await UserPermission.fetchOne({
      relationId: bookId
    })
    return res.data
  }
}

const mutations = {
  setUserPermissionsCourse(state, userPermissions) {
    state.userPermissions = userPermissions
  },
  setUserPermissionsBook(state, userPermissionsBook) {
    state.userPermissionsBook = userPermissionsBook
  },
  setPermissionAccessCourse(state, permissionAcessCourse) {
    state.permissionAccessCourse = permissionAcessCourse
  },
  setUserCoursePermissions(state, userCourses) {
    state.userPermissions = state.userPermissions.map(course => {
      const userCourseMatchWithCourse = userCourses.find(userCourse => userCourse.course === course.id)
      if (userCourseMatchWithCourse) {
        return {
          ...course,
          userCourse: userCourseMatchWithCourse,
          progress:
            userCourseMatchWithCourse.dataDetails && userCourseMatchWithCourse.dataDetails.progress
              ? userCourseMatchWithCourse.dataDetails.progress
              : 0
        }
      } else return course
    })
  }
}

const getters = {
  getUserPermissions: state => {
    return state.userPermissions
  },
  getUserPermissionsBook: state => {
    return state.userPermissionsBook
  },
  getPermissionAccessCourse: state => {
    return state.permissionAccessCourse
  }
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
}
